import {getDeviceType} from "../../library/device-type";

$(document).ready(function() {
    $(document).on("click", ".panel-dropdown-header", function() {
        if (!$(this).parents('.always-open').length || getDeviceType() !== 'mobile') {
            let panel = $(this).closest('.panel-dropdown');
            if (panel.hasClass('panel-closed')) {
                openPanel(panel);
            } else {
                closePanel(panel);
            }
        }
    });
});

function closePanel(panel) {
    if (!panel.hasClass('panel-closed')) {
        let body = panel.find('.panel-dropdown-body');
        body.slideUp('fast', function () {
            panel.addClass('panel-closed');
        });
    }
}

function openPanel(panel) {
    if (panel.hasClass('panel-closed')) {
        let body = panel.find('.panel-dropdown-body:first');
        let accordion = panel.data('accordion');
        if (accordion) {
            let accordionResponsive = panel.data('accordion-responsive');
            let deviceType = getDeviceType();
            $('.panel-dropdown[data-accordion="'+accordion+'"]').each(function() {
                if (!accordionResponsive || accordionResponsive.split(',').indexOf(deviceType) !== -1) {
                    closePanel($(this));
                }
            });
        }

        body.slideDown('fast', function () {
            panel.removeClass('panel-closed');
        });
    }
}