import algoliasearch from 'algoliasearch';
import instantsearch from "instantsearch.js";
import {connectAutocomplete, connectSearchBox} from 'instantsearch.js/es/connectors';
import {hideElement, showElement} from "./element-visibility";
import {configure} from "instantsearch.js/es/widgets";
import {highlight} from "instantsearch.js/es/helpers";

const appId = document.querySelector('#al-app').value;
const apiKey = document.querySelector('#al-ak').value;
const indexName = document.querySelector('#al-ti').value;
const searchClient = algoliasearch(appId, apiKey);
let language;
let maxSuggestions;
let oldSearcher = false;

function renderIndexListItem(index)
{
    let hits = index.hits;
    let html = '';
    let matchingTags = [];

    hits.forEach(function (hit) {
        if (!hit._highlightResult.translations[language].fullyHighlighted) {
            if (notAlreadyAdded(hit.translations[language], matchingTags, window.lastSearches)) {
                matchingTags.push({
                    name: hit.translations[language],
                    highlight: highlight({attribute: 'translations.' + language, highlightedTagName: 'span', hit: hit}),
                });
            }
        }
    });
    matchingTags.sort(function (a, b) {
        if (a.name.length > b.name.length) {
            return 1;
        } else if (a.name.length < b.name.length) {
            return -1;
        }

        return 0;
    });

    let addedSuggestions = window.lastSearches.length;
    matchingTags.forEach(function (tag) {
        if (addedSuggestions >= maxSuggestions) return;
        if (!oldSearcher) {
            html += `
                <div class="col-12 py-1 suggestion" data-suggestion="${tag.name}">
                    <span class="remove-saved-search px-2 invisible">&times;</span>
                    <span class="suggestion-name">${tag.highlight}</span>
                </div>
            `;
        } else {
            html += `
                <p class="search-suggestion" data-search="${tag.name}">
                    <span class="remove-suggestion">x</span>
                    <span class="text-suggestion">${tag.highlight}</span>
                </p>
            `;
        }
        addedSuggestions++;
    });

    return html;
}

function notAlreadyAdded(tagName, matchingTags, lastSearches) {
    return !matchingTags.some(matchingTag => matchingTag.name.localeCompare(tagName, undefined, {sensitivity: 'base'}) === 0) && !lastSearches.some(lastSearch => lastSearch.localeCompare(tagName, undefined, {sensitivity: 'base'}) === 0);
}

// Create the render function
const renderAutocomplete = (renderOptions) => {
    const {indices, widgetParams} = renderOptions;
    let div = widgetParams.container
    div.innerHTML = indices.map(renderIndexListItem);
    if (div.innerHTML !== '') {
        showElement(div);
    } else {
        hideElement(div);
    }
};

// Create the custom widget
const customAutocomplete = connectAutocomplete(renderAutocomplete);
let suggestionsTimeout = null;
const renderSearchBox = (renderOptions) => {
    const {refine, widgetParams } = renderOptions;
    let input = widgetParams.container;

    input.addEventListener('keyup', function (event) {
        let search = event.target.value;
        clearTimeout(suggestionsTimeout);
        suggestionsTimeout = setTimeout(function() {
            if (search !== '' && search.length >= 5) refine(event.target.value);
        }, 950);
    });
};

// create custom widget
const customSearchBox = connectSearchBox(renderSearchBox);

export function initSearcher(container, currentLanguage, productType, max, isOldSearcher = false)
{
    language = currentLanguage;
    maxSuggestions = max;
    oldSearcher = isOldSearcher;

    if (!container) {
        return;
    }

    if (language === 'sl' || language === 'hr') {
        language = 'en';
    }

    let searchFunction = (helper) => {
        if (helper.state.query) {
            if (productType) {
                helper.clearRefinements('productTypes').addFacetRefinement('productTypes', productType);
            }
            helper.search();
        }
    };

    let searchBox = isOldSearcher ? container.querySelector('input[type="search"]') : container.querySelector('.search');
    let tagsContainer = isOldSearcher ? container.querySelector('.search-suggestions .tags') :container.querySelector('.suggestions .tags');
    let configuration = {
        hitsPerPage: 25,
        restrictSearchableAttributes: ['translations.'+language],
        facets: ['productTypes'],
        typoTolerance: 'min',
        attributesToRetrieve: ['translations.'+language],
        analytics: false,
        distinct: 3,
    };
    if (language !== 'sl' && language !== 'hr') {
        configuration['removeStopWords'] = [language];
        configuration['queryLanguages'] = [language];
    }

    const instantSearch = instantsearch({
        indexName: indexName,
        searchClient: searchClient,
        searchFunction
    });

    instantSearch.addWidgets([
        customAutocomplete({
            container: tagsContainer,
        }),
        customSearchBox({
            container: searchBox,
        }),
        configure(configuration)
    ]);

    instantSearch.start();
}