import {getCookie, setCookie} from "../../library/cookies";
import {popupInfoDialog} from "../../library/info-dialog";

$(document).ready(function() {
    if ($('#divContainerFluidMessageSOS').length) {
        showSOSComponent();
    }

    $(document).on("click","#messageSOSDown",function() {
        $('.containerFluidMessageSOS').slideUp( "fast", function() {
            $('#messageSOSDropdown').css('display', 'none');
            $('#messageSOSNotDropdown').css('display', 'block');
            $('.containerFluidMessageSOS').slideDown( "fast", function() {});
        });
    });

    $(document).on("click","#messageSOSNotDropdown",function() {
        $('.containerFluidMessageSOS').slideUp( "fast", function() {
            $('#messageSOSNotDropdown').css('display', 'none');
            $('#messageSOSDropdown').css('display', 'block');
            $('.containerFluidMessageSOS').slideDown( "fast", function() {
                $('#wordsSOS').focus();
            });
        });
    });

    $(document).on("click","#messageSOSButton",function() {
        sendMessageSOS();
    });
});

function showSOSComponent() {
    let pageViews = '';
    if(!localStorage.getItem('_tv_cookie_choice')){
        pageViews = getCookie('_tv_page_views');
    }
    if (pageViews == "") {
        pageViews = 0;
    }
    pageViews ++;

    if(!localStorage.getItem('_tv_cookie_choice')){
        setCookie('_tv_page_views', pageViews, 7);
    }
    $.ajax({
        url: '/ajax/mensaje/show-message-sos',
        type: "post",
        success: function(data){
            let container = $('#divContainerFluidMessageSOS');
            container.addClass('containerFluidMessageSOS');
            container.addClass('messageSOSUp');
            container.html(data.view);
            if (pageViews == 10) {
                $('#messageSOSNotDropdown').click();
            }
        }
    });
}
function sendMessageSOS() {
    let words = $('#wordsSOS').val();
    let email = $('#emailSOS').val();
    $.post(
        '/ajax/mensaje/send-message-sos',
        {words: words, email:email},
        function(data) {
            $('#messageSOSDown').click();
            popupInfoDialog(data.text);
        },
        'json'
    );
}