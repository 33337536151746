import {setCookie, getCookie} from "./cookies";

const lastSearchesCookie = '_tv_searches';
const maxLastSearches = 4;
export function getLastSearches()
{
    let lastSearches = getCookie(lastSearchesCookie);
    let searches = lastSearches ? JSON.parse(decodeURIComponent(lastSearches).replaceAll('+', ' ')).reverse() : [];
    let numSearches = searches.length;
    if (numSearches) {
        if (numSearches > maxLastSearches) {
            searches = searches.slice(-maxLastSearches);
        }
    }

    return searches;
}

export function getLastSearchesByName(name)
{
    let lastSearches = getCookie(lastSearchesCookie);
    if (!lastSearches) return [];
    let searches = JSON.parse(decodeURIComponent(lastSearches).replaceAll('+', ' '));

    return searches.filter(element => element.startsWith(name, 0)).reverse();
}

export function removeSearch(search)
{
    let lastSearches = getCookie(lastSearchesCookie);
    lastSearches = JSON.parse(decodeURIComponent(lastSearches).replaceAll('+', ' '));
    let index = lastSearches.indexOf(search);
    if (index > -1) lastSearches.splice(index, 1);
    setCookie(lastSearchesCookie, JSON.stringify(lastSearches), 60);
}