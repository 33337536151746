import {trackEvent, trackLinkEvent} from "../../library/analytics";
import {onVisible} from "../../website/scripts/Tools/visible-element-observer";

$( document ).ready(function() {
    onVisible(document.querySelectorAll(".fire-footer-events"), function (element) {
        getPhoneInfo();
    }, 0.1);

    $('.social-link').click(function () {
        window.open($(this).attr('data-url'));
        trackEvent('Footer', 'SocialMedia', $(this).attr('title'));
    });
    $('.linkSocial').click(function () {
        window.open($(this).attr('data-url'));
        trackEvent('Footer', 'SocialMedia', $(this).attr('title'));
    });
    $('#footer-customers .footer-std-link a').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'User', link.attr('title'), link, event);
    });
    $('#footer-aboutus .footer-link a').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'AboutUs', link.attr('title'), link, event);
    });
    $('#footer-stickers .footer-link a').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'AboutProducts', link.attr('title'), link, event);
    });
    $('#footer-conditions .footer-link a').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'Conditions', link.attr('title'), link, event);
    });
    $('#footer-blog .footer-link a').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'Blog', 'Blog', link, event);
    });
    $('#footer-link-outlet').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'Outlet', 'Outlet', link, event);
    });
    $('#footer-link-tenquality').click(function (event) {
        event.preventDefault();
        let link = $(this);
        window.location.href = link.attr('data-url');
        trackEvent('Footer', 'TenQuality');
    });
    $('#footer-link-oxfam').click(function (event) {
        event.preventDefault();
        let link = $(this);
        window.open(link.attr('data-url'));
        trackEvent('Footer', 'Oxfam');
    });
    $('#footer-link-winstickers').click(function (event) {
        event.preventDefault();
        let link = $(this);
        window.location.href = link.attr('data-url');
        trackEvent('Footer', 'WinStickers');
    });
    $('#footer-link-home').mousedown(function (event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Footer', 'Home', '', link, event);
    });
    $('#footer img.lazy-load-img').unveil(50, function () {
        $(this).removeClass('lazy-load-img')
    });
});

function getPhoneInfo() {
    $.post(
        '/ajax/get-phone-info',
        {},
        function(response) {
            $('.show-contact-info').html(response.view);
        }
    );
}