import {post} from "./ajax";
import {openModalFromHtml} from "./modal";
import {setCookie, showModalCookies} from "./cookies";

export function getCountryCodeByIp(callback)
{
    post(
        '/ajax/website/country/get-country-code-by-ip',
        {},
        function(data) {
            callback(data.countryCode, data.cookieCountry);
        }
    );
}

export function showCountrySelectPopUp(countryCode)
{
    post(
        '/ajax/website/country/get-country-select-popup',
        {countryCode: countryCode},
        function(data) {
            if (data.popUp) {
                openModalFromHtml(data.popUp, {backdrop: 'static'});
                document.querySelector('.modal-backdrop').classList.remove('show');
            } else {
                setCookie('_tv_i18n_identified', 'y', 60);
                showModalCookies();
            }
        },
        function() {
            setCookie('_tv_i18n_identified', 'y', 60);
            showModalCookies();
        }
    );
}