import {slideDown, slideUp} from "./element-visibility";
import {ga4TrackSimpleEvent} from "./analytics";

export function navigateHeaderMenu(id, updateBackButton)
{
    closeCountrySelector();
    let current = document.querySelector('.hm-section.active');
    let next = document.querySelector(`.hm-section[data-id="${id}"]`);
    current.classList.remove('active');
    next.classList.add('active');
    if (updateBackButton) {
        let backButton = next.querySelector('.hm-back');
        if (backButton) {
            backButton.dataset.id = current.dataset.id;
        }
    }
}

export function replaceCountryLinks()
{
    let alternates = document.querySelectorAll('link[rel=alternate]');
    alternates.forEach(function(alternate) {
        let hreflang = alternate.getAttribute('hreflang');
        let locale = hreflang.replace("-","_");
        let href = alternate.getAttribute('href');
        let link = document.querySelector(`.selector-website[data-locale=${locale}]`);
        if (link) link.setAttribute("href", href);
    });
}

export function toggleCountrySelector()
{
    let button = document.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status === 'in-progress') return;

    if (status === 'closed') {
        openCountrySelector();
    } else {
        closeCountrySelector();
    }
}

function openCountrySelector()
{
    let button = document.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status != 'closed') return;
    let icon = button.querySelector('.direction-icon');
    let countrySelector = document.querySelector('#country-selector');

    button.dataset.status = 'in-progress';
    icon.classList.add('fa-rotate-180');
    slideDown(countrySelector, 200, function() {
        button.dataset.status = 'open';
    });
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'open'
        }
    );
}

function closeCountrySelector()
{
    let button = document.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status != 'open') return;
    let icon = button.querySelector('.direction-icon');
    let countrySelector = document.querySelector('#country-selector');

    button.dataset.status = 'in-progress';
    icon.classList.remove('fa-rotate-180');
    slideUp(countrySelector, 200, function() {
        button.dataset.status = 'closed';
    });
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'close'
        }
    );
}