require('./files/general');
require('./files/loadComposition');
require('./files/header');
require('./files/left-menu');
require('./files/footer');
require('./files/product-shopping-cart');
require('./files/message-sos');
require('./files/popup-promotion');
require('./files/login');
require('./files/i18nIdentifier');
require('./files/recommendations');
require('./files/loyaltyBar');
require('./files/related-product');
require('./files/slide-up-popup');
require('./files/add-to-cart-product-options');
require('./files/panel-dropdown');

require('../../css/web/general.css');
require('../../sass/website/Icons/font.scss');
require('../../css/web/header.css');
require('../../sass/web/left-menu.scss');
require('../../css/web/footer.css');
require('../../sass/website/Social/icons.scss');
require('../../css/web/shopping-cart.css');
require('../../sass/web/shopping-cart.scss');
require('../../css/web/menu-right.css');
require('../../sass/web/menu-left-right-full.scss');
require('../../css/web/message-sos.css');
require('../../css/web/cookies.css');
require('../../css/web/loyalty-bar.css');
require('../../css/web/promo-popup.css');
require('../../css/web/i18n-identifier.css');
require('../../sass/web/_related-product.scss');
require('../../sass/web/_product-advantage.scss');
require('../../sass/web/popup-slide-up.scss');
require('../../sass/web/add-to-cart.scss');
require('../../sass/web/_panel-dropdown.scss');
require('../../sass/web/_header-menu.scss');
require('../../sass/web/interaction-efects.scss');
require('../../sass/web/klarna-banner.scss');
require('../../sass/web/priority-service.scss');
require('../../sass/website/Logo/font.scss');
require('../../sass/web/widget-help.scss');
require('@splidejs/splide/dist/css/splide.min.css');