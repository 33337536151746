$(document).ready(function(){
    let timer = null;
    if ($('.loyalty-bar').length) {
        timer = window.setInterval(switchLoyaltyImg, 5000); //5s
    }
});

function switchLoyaltyImg() {
    let currentItem = getCurrentItem();
    let currentMobileItem = getCurrentMobileItem();
    let nextItem = getNextItem(currentItem);
    let nextMobileItem = getNextMobileItem(currentMobileItem);
    currentItem.slideUp(350, function() {
        nextItem.slideDown(350);
    });
    currentMobileItem.slideUp(350, function() {
        nextMobileItem.slideDown(350);
    });
}

function getCurrentItem() {
    return $('.loyalty-item:visible');
}

function getCurrentMobileItem() {
    return $('.loyaltyMobile-item:visible');
}

function getNextItem(currentItem) {
    if (currentItem.next('.loyalty-item').length) {
        return currentItem.next('.loyalty-item');
    } else {
        return $('.loyalty-item').first();
    }
}
function getNextMobileItem(currentMobileItem) {
    if (currentMobileItem.next('.loyaltyMobile-item').length) {
        return currentMobileItem.next('.loyaltyMobile-item');
    } else {
        return $('.loyaltyMobile-item').first();
    }
}