import {getCookie, setCookie} from "../../library/cookies";
import {trackLinkEvent} from "../../library/analytics";
import {getCountryCodeByIp} from "../../website/webpack/modules/country";
import {acceptAllCookies} from "../../website/webpack/modules/cookies";

$(document).ready(function(){
    let userCountry = sessionStorage.getItem('_tv_user_country');
    let i18nIdentified = getCookie('_tv_i18n_identified');
    let cookieChoice = getCookie('_tv_cookie_choice');

    if (!userCountry && !i18nIdentified) {
        getCountryCodeByIp(function(countryCode, cookieCountry) {
            sessionStorage.setItem('_tv_user_cookie_country', cookieCountry);
            if (countryCode) sessionStorage.setItem('_tv_user_country', countryCode);
            else if (cookieChoice) setCookie('_tv_i18n_identified', 'y', 30);

            if (cookieCountry) {
                if (!cookieChoice) showCookiesModal();
                else showCountrySelectPopUp(countryCode);
            } else {
                showCountrySelectPopUp(countryCode);
                acceptAllCookies(365);
            }
        });
    } else if (!i18nIdentified && cookieChoice) {
        if (userCountry) {
            showCountrySelectPopUp(userCountry);
        } else {
            if (cookieChoice) setCookie('_tv_i18n_identified', 'y', 30);
            else {
                let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country');
                if (cookieCountry) {
                    showCookiesModal();
                } else {
                    acceptAllCookies(365);
                }
            }
        }
    } else {
        let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country');
        if (cookieCountry) {
            showCookiesModal();
        } else {
            acceptAllCookies(365);
        }
    }

    $(document).on('mousedown', '.i18n-option-box:not(.option-continue)', function() {
        if (event.which == 3) {return;}
        let link = $(this).find('a');
        trackLinkEvent('i18nIdentifier', 'MoveTo', link.attr('title'), link);
    });
    $(document).on('click', '#i18n-close, .i18n-current-box, .option-continue', function() {
        setCookie('_tv_i18n_identified', 'y', 60);
        $('#i18n-container').slideUp(400, function(){
            $(this).remove();
            showCookiesModal();
        });
    });
});

function showCookiesModal()
{
    let cookielessPage = document.querySelector('.cookieless-page')
    if (!cookielessPage) {
        if (getCookie('_tv_cookie_consent') === "" && getCookie('_tv_in_checkout') === "") {
            $.post(
                '/ajax/header/cookie_modal',
                {},
                function (data) {
                    $(data.cookieMsg).modal();
                }
            );
        }
    }
}

function showCookiesOffcanvas()
{
    let cookielessPage = document.querySelector('.cookieless-page')
    if (!cookielessPage) {
        if (getCookie('_tv_cookie_consent') === "" && getCookie('_tv_in_checkout') === "") {
            $.post(
                '/ajax/header/cookie_offcanvas',
                {},
                function (data) {
                    $('body').append(data.cookieMsg);
                    $('body').css('overflow', 'hidden');
                }
            );
        }
    }
}

function showCookiesMessage() {
    if (getCookie('_tv_cookie_consent') === "" && getCookie('_tv_in_checkout') === "") {
        $.post(
            '/ajax/header/cookie_msg',
            {},
            function(data) {
                $('#cookieChoiceInfo').html(data.cookieMsg);
                $('#cookieChoiceInfo').slideDown(350);
                /*if(!localStorage.getItem('_tv_cookie_choice')){
                    setCookie('_tv_cookie_consent', 'y', 365);
                }*/
/*
                // Change temporary header height for show cookies policy
                var height = 255;
                if ($('#mobileMenu').is(':visible')) {
                    height = 155;
                }
                $('#headCnt').css('height', height+'px');
                //#breadcrumbDiv, .containerFluidWinstickerProduct
                $('#breadcrumbDiv, .containerFluidWinstickerProduct').addClass('remove-margin-top');
 */
            }
        );
    }
}

function showCountrySelectPopUp(countryCode) {
    $.post(
        '/ajax/i18n/identifier',
        {countryCode: countryCode},
        function (data) {
            if (data.hasBetterOptions) {
                displayi18nOptions(data.content);
            } else if (getCookie('_tv_cookie_choice') !== "") {
                setCookie('_tv_i18n_identified', 'y', 60);
                showCookiesModal();
            }
        }
    );
}

function displayi18nOptions(content) {
    $('<div></div>')
        .attr('id', 'i18n-container')
        .hide()
        .append(content)
        .appendTo('body')
        .slideDown();
}