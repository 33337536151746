export function getDeviceType()
{
    let width = window.innerWidth;
    if (width < 768 ) {
        return 'mobile';
    } else if (width >= 768 && width <= 992) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}

export function isMobile()
{
    return getDeviceType() == 'mobile';
}

export function isTablet()
{
    return getDeviceType() == 'tablet';
}

export function isDesktop()
{
    return getDeviceType() == 'desktop';
}

export function isIOS()
{
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
}