import {trackEvent} from "../../library/analytics";
import {isMobile} from "../../website/webpack/modules/devices";

$(document).ready(function(){
    $(document).on('submit', '.loginForm', function(e) {
        e.preventDefault();
        let form = $(this);
        let url = form.attr('action');
        let data = form.serialize();
        let analyticsCategory = form.data('scope');
        trackEvent(analyticsCategory, 'SignIn', 'Login');
        $('.responseLogin').empty();
        $.post(
            url,
            data,
            function(data){
                if (data.done) {
                    dataLayer.push(
                        {
                            "event": "login",
                            "method": "form"
                        }
                    );
                    if (data.url){
                        window.location.replace(data.url);
                    } else {
                        $('.login-check-modal').modal('hide');
                    }
                } else {
                    $('.responseLogin').html(data.msg);
                    if (isMobile()) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }
            },
            'json'
        );
    });
    $(document).on('click', '.sendRecoverBtn', function() {
        let recoverMail = $(this).parents('.recoverPass').find('[name="recoverMail"]').val();
        let analyticsCategory = $(this).data('scope');
        trackEvent(analyticsCategory, 'SignIn', 'RecoverPassword');
        $.post(
            '/ajax/website/user/ask-recovery-password',
            {email: recoverMail},
            function(data){
                if(data.done) {
                    $('.error-response').addClass('hidden');
                    $('.success-response').html(data.message);
                    $('.success-response').removeClass('hidden');
                    setTimeout(function() {
                        $('.success-response').empty();
                        $('.success-response').addClass('hidden');
                        $('.responseLogin').empty();
                        $('.recoverPass').slideUp('fast');
                        $('.loginForm').slideDown('fast');
                        $('.no-register').slideDown('fast');
                    }, 4000);
                } else {
                    $('.success-response').addClass('hidden');
                    $('.error-response').html(data.message);
                    $('.error-response').removeClass('hidden');
                    if (isMobile()) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }
            },
            'json'
        );
    });

    $(document).on('submit', '.registerForm', function(e) {
        e.preventDefault();
        let form = $(this);
        let url = form.attr('action');
        let data = form.serialize();
        let analyticsCategory = form.data('scope');
        trackEvent(analyticsCategory, 'NewUser', 'Register');
        $('.responseRegister').empty();
        $.post(
            url,
            data,
            function(data){
                if (data.done) {
                    trackEvent('UserSignIn', 'Register', '');
                    dataLayer.push(
                        {
                            "event": "sign_up",
                            "method": "form"
                        }
                    );
                    if (data.url){
                        window.location.replace(data.url);
                    } else {
                        $('.login-check-modal').modal('hide');
                    }
                } else {
                    trackEvent('RegisterLogin', 'Register', 'error');
                    $('.responseRegister').html(data.msg);
                    if (isMobile()) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }
            },
            'json'
        );
    });
    $(document).on('click', '.showRecoverPass', function() {
        $('.responseLogin, .responseRegister').empty();
        $('.loginForm').slideUp('fast');
        $('.no-register').slideUp('fast');
        $('.recoverPass').slideDown('fast');
    });
    $(document).on('click', '.sendRecoverClose', function() {
        $('.responseLogin, .responseRegister').empty();
        $('.recoverPass').slideUp('fast');
        $('.loginForm').slideDown('fast');
        $('.no-register').slideDown('fast');
    });
    $(document).on('click', '.showRegister', function() {
        $('.responseLogin, .responseRegister').empty();
        $('.loginForm').slideUp('fast');
        $('.no-register').slideUp('fast');
        $('.registerForm').slideDown('fast', function() {
            google.accounts.id.renderButton(
                document.querySelector('.registerForm').querySelector('.g_id_signin'),
                { type: "icon", size: "large", theme: "outline" }  // customization attributes
            );
        });
    });
    $(document).on('click', '.backLogin', function () {
        $('.responseLogin, .responseRegister').empty();
        $('.loginForm').slideDown('fast', function() {
            google.accounts.id.renderButton(
                document.querySelector('.loginForm').querySelector('.g_id_signin'),
                { type: "icon", size: "large", theme: "outline" }  // customization attributes
            );
        });
        $('.no-register').slideDown('fast');
        $('.registerForm').slideUp('fast');
    });
});