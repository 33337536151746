export function createOffcanvasFromHtml(html, parentElement, options = {})
{
    if (typeof html !== 'string') return null;
    let div = document.createElement('div');
    div.innerHTML = html.trim();
    let offcanvas = div.firstChild;
    parentElement.appendChild(offcanvas);

    return new bootstrap.Offcanvas(document.getElementById(offcanvas.id), options);
}

export function openOffcanvasFromHtml(html, options = {}, removeOnHide = true)
{
    let offcanvas = createOffcanvasFromHtml(html, document.body, options);
    offcanvas.show();

    if (removeOnHide) offcanvas._element.addEventListener('hidden.bs.offcanvas', function () {
        this.remove();
    });
    offcanvas._element.addEventListener('hide.bs.offcanvas', function (event) {
        if (this.dataset.preventClosing == 'true') event.preventDefault();
    });
}

export function getOffcanvas(id)
{
    let offcanvas = document.getElementById(id);

    return bootstrap.Offcanvas.getOrCreateInstance(offcanvas);
}

export function getOffcanvasBySelector(selector)
{
    let offcanvas = document.querySelector(selector);

    return bootstrap.Offcanvas.getOrCreateInstance(offcanvas);
}

export function hideOffcanvas(id)
{
    document.querySelector('#'+id).dataset.preventClosing = 'false';
    let offcanvas = getOffcanvas(id);

    if (offcanvas) {
        offcanvas.hide();
    }
}

export function showOffcanvas(id)
{
    let offcanvas = getOffcanvas(id);

    if (offcanvas) {
        offcanvas.show();
    }
}