import {getCookie, setCookie} from "../../library/cookies";
import {popupInfoDialog} from "../../library/info-dialog";
import {showPromoChollazos} from "../../website/scripts/Promotion/popup-chollazos";

$(document).ready(function(){
    $.post(
        '/ajax/promo/popup',
        {},
        function(data) {
            if (data.promoId) {
                let seenPromotions;
                if(!localStorage.getItem('_tv_cookie_choice')){
                    seenPromotions = getCookie('_tv_promo_popup').split(',');
                }
                let seen = false;
                $.each(seenPromotions, function(key, value) {
                    if (data.promoId == value) {
                        seen = true;
                    }
                });

                if (!seen) {
                    popupInfoDialog(data.popup);
                    seenPromotions = seenPromotions.join(',') + ',' + data.promoId;
                    if(!localStorage.getItem('_tv_cookie_choice')){
                        setCookie('_tv_promo_popup', seenPromotions.replace(/^,+/,''), 1);
                    }
                }
            }
        }
    );
    showPromoChollazos(false);
});