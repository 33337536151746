import {addOverlay, removeOverlay} from "../../library/overlay";
import {navigateHeaderMenu, replaceCountryLinks} from "../../website/webpack/modules/left-menu";
import {ga4TrackSimpleEvent} from "../../website/webpack/modules/analytics";

$(document).ready(function() {
    replaceCountryLinks();

    $('.open-left-menu').click(openLeftMenu);
    $('#left-menu .close-left-menu').click(closeLeftMenu);
    $('#left-menu #country-selector-btn').click(toggleCountrySelector);
    $('.hm-navigate').click(function() {
        closeCountrySelector();
        navigateHeaderMenu($(this).data('id'), true);
        ga4TrackSimpleEvent(
            {
                event: 'header_menu',
                method: 'navigate',
                link_id: this.dataset.linkId ? this.dataset.linkId : null,
                link_text: this.dataset.linkText ? this.dataset.linkText : null
            }
        );
    });
    $('.hm-back').click(function() {
        closeCountrySelector();
        navigateHeaderMenu($(this).data('id'), false);
        ga4TrackSimpleEvent(
            {
                event: 'header_menu',
                method: 'back'
            }
        );
    });

    $(document).on('click', '.overlay', function() {
        let leftMenu = $("#left-menu");
        if (leftMenu && leftMenu.hasClass('open')) {
            closeLeftMenu();
        }
    });
});

function openLeftMenu() {
    removeOverlay();
    $('#left-menu').addClass('open');
    addOverlay();
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'open'
        }
    );
}

function closeLeftMenu() {
    removeOverlay();
    $('#left-menu').removeClass('open');

    setTimeout(function () {
        navigateHeaderMenu($('.hm-section[data-type="main"]').data('id'), false);
        ga4TrackSimpleEvent(
            {
                event: 'header_menu',
                method: 'close'
            }
        );
    }, 200);
}

function toggleCountrySelector() {
    let button = $('#left-menu #country-selector-btn');
    let status = button.data('status');
    if (status === 'in-progress') return;

    if (status === 'closed') {
        openCountrySelector();
    } else {
        closeCountrySelector();
    }
}

function openCountrySelector() {
    let button = $('#left-menu #country-selector-btn');
    let status = button.data('status');
    if (status != 'closed') return;
    let icon = button.find('.direction-icon');
    let countrySelector = $('#left-menu #country-selector');

    button.data('status', 'in-progress');
    icon.addClass('fa-rotate-180');
    countrySelector.slideDown('fast', function() {
        button.data('status', 'open');
    });
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'open'
        }
    );
}

function closeCountrySelector() {
    let button = $('#left-menu #country-selector-btn');
    let status = button.data('status');
    if (status != 'open') return;
    let icon = button.find('.direction-icon');
    let countrySelector = $('#left-menu #country-selector');

    button.data('status', 'in-progress');
    icon.removeClass('fa-rotate-180');
    countrySelector.slideUp('fast', function() {
        button.data('status', 'closed');
    });
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'close'
        }
    );
}