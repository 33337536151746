import {getCookie, setCookie} from "../../library/cookies";

$(document).ready(function() {
    if (getCookie('_tv_uuid') === "" /*&& getCookie('_tv_cookie_consent') !== ""*/) {
        if (!Date.now) {
            Date.now = function now() {
                return new Date().getTime();
            };
        }
        $.getJSON('/ajax/recommendations/get-uuid', function(data) {
            let userId = data.userId;
            if (userId) {
                if(!localStorage.getItem('_tv_cookie_choice')){
                    setCookie('_tv_uuid', Math.floor(Date.now() /1000) + '_' + userId, 3650);
                }
            }
        });
    }

    $.productBrowseRating = function(productId, productType) {
        executeProductScore(productId, productType, 'BROWSE');
        setTimeout(function() {
            executeProductScore(productId, productType, 'BROWSE');
        }, 5000);
    };

    $.productAddToCartRating = function(productId, productType) {
        executeProductScore(productId, productType, 'ADD_TO_CART');
    };

    $.productPurchaseRating = function(productId, productType) {
        executeProductScore(productId, productType, 'BUY');
    };

    $.categoryRating = function(categoryType) {
        executeCategoryScore(categoryType, 'BROWSE');
        setTimeout(function() {
            executeCategoryScore(categoryType, 'BROWSE');
        }, 15000);
    }
});

function executeProductScore(productId, productType, scoreType) {
    var userId = getCookie('_tv_uuid');
    if (getCookie('_tv_uuid') !== "") {
        if (!$.isArray(productId)) {
            var products = [];
            products.push(productId);
            productId = products;
        }
        $.post(
            '/ajax/recommendations/product-score',
            {
                userId: userId,
                productRef: productId,
                productType: productType,
                score: scoreType
            },
            'json'
        );
    }
}

function executeCategoryScore(categoryType, scoreType) {
    var userId = getCookie('_tv_uuid');
    if (getCookie('_tv_uuid') !== "") {
        $.post(
            '/ajax/recommendations/category-score',
            {
                userId: userId,
                pathname: location.pathname,
                categoryType: categoryType,
                score: scoreType,
                categories: getQueryStringParams()
            },
            'json'
        );
    }
}

function getQueryStringParams() {
    var match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query  = window.location.search.substring(1);

    var urlParams = [];
    while (match = search.exec(query)) {
        var prefix = '';
        if (decode(match[1]).indexOf('theme') !== -1) {
            prefix = 'th-';
        } else if (decode(match[1]).indexOf('surface') !== -1) {
            prefix = 'su-';
        } else if (decode(match[1]).indexOf('location') !== -1) {
            prefix = 'lo-';
        } else if (decode(match[1]).indexOf('style') !== -1) {
            prefix = 'st-';
        }
        urlParams.push(prefix+decode(match[2]));
    }
    return urlParams;
}