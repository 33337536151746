import {getCookie, setCookie} from "../../../library/cookies";
import {clickEvent} from "../../webpack/modules/events";
import {getModal, openModalFromHtml} from "../../webpack/modules/modal";
import {post} from "../../webpack/modules/ajax";

let popupEventSend = false;
let chollazosInterval = null;
export function showPromoChollazos(useBootstrap) {
    if (typeof (Storage) !== 'undefined' && !sessionStorage.getItem('tvn_shchollospop')) {
        let d = new Date();
        sessionStorage.setItem('tvn_shchollospop', d.getTime());
    }
    if (canShowChollazosPopup()) {
        chollazosInterval = setInterval(function() {
            showPromo(useBootstrap);
        }, 40000);
        showPromo(useBootstrap);
    }
}
clickEvent('.dismiss-chollazos-popup', true, function() {
    chollazosPopupDismissed();
});

function showPromo(useBootstrap) {
    if (typeof useBootstrap === 'undefined') {
        useBootstrap = true;
    }
    if (canShowChollazosSessionPopup()) {
        if (useBootstrap) {
            if (!getModal('popup-chollazos')) {
                openModal(useBootstrap);
                if (!popupEventSend) {
                    dataLayer.push({
                        event: 'chollazos_show',
                        method: 'popup'
                    });
                    popupEventSend = true;
                }
            }
        } else {
            openModal(useBootstrap);
            if (!popupEventSend) {
                dataLayer.push({
                    event: 'chollazos_show',
                    method: 'popup'
                });
                popupEventSend = true;
            }
        }
        clearInterval(chollazosInterval);
    }
}

function openModal(useBootstrap) {
    post(
        '/ajax/website/promo/chollazos',
        {
            oldSite: !useBootstrap
        },
        function(response) {
            if (useBootstrap) {
                openModalFromHtml(response.modal, {}, true);
                let popup = getModal('popup-chollazos');
                if (popup) {
                    popup._element.addEventListener('hide.bs.modal', function () {
                        chollazosPopupDismissed();
                    });
                }
            } else {
                showNonBootstrapModal(response.modal);
            }
        }
    );
}

function showNonBootstrapModal(text) {
    let dialog = text;
    if ($('#popup-chollazos').length) {
        $('#popup-chollazos').modal('hide');
        $('#popup-chollazos').remove();
    }
    if ($('.modal-backdrop').length) {
        $('.modal-backdrop').remove();
    }
    $(dialog).modal({
        autoOpen: false,
        width: 1630,
        position: 'center',
        modal: true,
        resizable: false,
        closeOnEscape: false

    });
}

function canShowChollazosSessionPopup() {
    let canShow = true;
    let skipPopup = document.getElementsByClassName('skip-newsletter-popup').length;
    if (!skipPopup) {
        if (typeof (Storage) !== 'undefined') {
            if (sessionStorage.getItem('tvn_shchollospop')) {
                let d1 = new Date();
                let timeDiff = d1.getTime() - sessionStorage.getItem('tvn_shchollospop');
                canShow = (timeDiff >= 40000) && (timeDiff < 50000);
            } else {
                canShow = true;
            }
        } else {
            canShow = false;
        }
    } else {
        canShow = false;
    }

    return canShow;
}

function canShowChollazosPopup() {
    let canShow = true;
    let skipPopup = document.getElementsByClassName('skip-newsletter-popup').length;
    if (!skipPopup) {
        if (typeof (Storage) !== 'undefined') {
            if (localStorage.getItem('tvn_shchollospop')) {
                let d1 = new Date();
                canShow = ((d1.getTime() - localStorage.getItem('tvn_shchollospop'))/1000/86400) > 1;
            } else {
                canShow = true;
            }
        } else {
            canShow = !(getCookie('tvn_shchollospop') === '1');
        }
    } else {
        canShow = false;
    }

    return canShow;
}

function chollazosPopupDismissed() {
    if (typeof (Storage) !== 'undefined') {
        let d = new Date();
        localStorage.setItem('tvn_shchollospop', d.getTime());
    } else {
        setCookie('tvn_shchollospop', '1', 30);
    }
}