export function slideUp(element, duration = 200, callback)
{
    if (!element || window.getComputedStyle(element).display === 'none') {
        if (callback) callback();
        return;
    }
    element.style.transitionProperty = 'height, margin, padding';
    element.style.transitionDuration = duration + 'ms';
    element.style.boxSizing = 'border-box';
    element.style.height = element.offsetHeight + 'px';
    element.offsetHeight;
    element.style.overflow = 'hidden';
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    setTimeout(function() {
        element.style.display = 'none';
        element.style.removeProperty('height');
        element.style.removeProperty('padding-top');
        element.style.removeProperty('padding-bottom');
        element.style.removeProperty('margin-top');
        element.style.removeProperty('margin-bottom');
        element.style.removeProperty('overflow');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
        if (callback) callback();
    }, duration);
}

export function slideDown(element, duration = 200, callback)
{
    if (!element || window.getComputedStyle(element).display !== 'none') {
        if (callback) callback();
        return;
    }
    element.style.removeProperty('display');
    let display = window.getComputedStyle(element).display;
    if (display === 'none') display = 'block';
    element.style.display = display;
    let height = element.offsetHeight;
    element.style.overflow = 'hidden';
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    element.offsetHeight;
    element.style.boxSizing = 'border-box';
    element.style.transitionProperty = "height, margin, padding";
    element.style.transitionDuration = duration + 'ms';
    element.style.height = height + 'px';
    element.style.removeProperty('padding-top');
    element.style.removeProperty('padding-bottom');
    element.style.removeProperty('margin-top');
    element.style.removeProperty('margin-bottom');
    setTimeout(function() {
        element.style.removeProperty('height');
        element.style.removeProperty('overflow');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
        if (callback) callback();
    }, duration);
}

export function slideUpBySelector(selector, duration = 200, callback)
{
    let elements = document.querySelectorAll(selector);
    elements.forEach(function(element) {
        slideUp(element, duration);
    });

    setTimeout(function() {
        if (callback) callback();
    }, duration);
}

export function slideDownBySelector(selector, duration = 200, callback)
{
    let elements = document.querySelectorAll(selector);
    elements.forEach(function(element) {
        slideDown(element, duration);
    });

    setTimeout(function() {
        if (callback) callback();
    }, duration);
}

export function showElement(element)
{
    if (element) {
        element.style.display = '';
    }
}

export function hideElement(element)
{
    if (element) {
        element.style.display = 'none';
    }
}

export function showElementBySelector(selector)
{
    let elements = document.querySelectorAll(selector);
    elements.forEach(function(element) {
        showElement(element);
    });
}

export function hideElementBySelector(selector)
{
    let elements = document.querySelectorAll(selector);
    elements.forEach(function(element) {
        hideElement(element);
    });
}

export function fadeIn(element, duration = 200, callback)
{
    if (!element || window.getComputedStyle(element).display !== 'none') {
        if (callback) callback();
        return;
    }
    element.style.opacity = 0;
    element.style.filter = "alpha(opacity=0)";
    element.style.display = "inline-block";
    element.style.visibility = "visible";

    let opacity = 0;
    let timer = setInterval(function() {
        opacity += 50 / duration;
        if (opacity >= 1) {
            opacity = 1;
        }
        element.style.opacity = opacity;
        element.style.filter = "alpha(opacity=" + opacity * 100 + ")";

        if (opacity == 1) {
            clearInterval(timer);
            if (callback) callback();
        }
    }, 50);
}

export function fadeOut(element, duration = 200, callback)
{
    if (!element || window.getComputedStyle(element).display === 'none') {
        if (callback) callback();
        return;
    }
    let opacity = 1;
    let timer = setInterval(function() {
        opacity -= 50 / duration;
        if (opacity <= 0) {
            opacity = 0;
            element.style.display = "none";
            element.style.visibility = "hidden";
        }
        element.style.opacity = opacity;
        element.style.filter = "alpha(opacity=" + opacity * 100 + ")";

        if (opacity == 0) {
            clearInterval(timer);
            if (callback) callback();
        }
    }, 50);
}